p
{
    font-size: 18;
}

h2
{
    margin-top: 50px;
    margin-bottom: 20px;
}

.content
{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 92px; /*NavBar height*/
    padding-bottom: 100px; /*a little extra space at the bottom to indicate the content is over*/
}